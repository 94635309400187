

class TimeUtils {
    public static durationToText(value): string {
        if(value==null || value <0) {
            return 'N/A'
        } else if(value < 60 * 1000) {
            return `${(value/1000).toFixed(0)} 秒`;
        } else if(value < 60 * 60 * 1000) {
            return `${(value/(60 * 1000)).toFixed(0)} 分`;
        } else if(value < 24 * 60 * 60 * 1000) {
            return `${(value/(60 * 60 * 1000)).toFixed(0)} 小时 ${((value % (60 * 60 * 1000)) / (60 * 1000)).toFixed(0)} 分`;
        } else {
            return `${(value/(24 * 60 * 60 * 1000)).toFixed(0)} 天 ${((value % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)).toFixed(0)} 小时`;
        }
        return 'N/A';
    }
}

export default TimeUtils;
