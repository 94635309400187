import { useEffect, useState } from "react";
import DeviceApi from "../../../../../api/DeviceApi";
import { Content, DeviceName, DeviceNetworkTrafficWrapper, DeviceTrafficStatisticsItem, Title, TrafficFree, TrafficUsageBar, TrafficUsageText, TrafficUsed } from "./DeviceNetworkTraffix.styled";

interface DeviceNetworkTrafficProps {

}

let DeviceNetworkTraffic: React.FC<DeviceNetworkTrafficProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // state
    let [deviceViews, setDeviceViews] = useState([]);

    // onload
    useEffect(()=> {
        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            deviceViews = deviceViews.map((deviceView)=> {
                deviceView.node.trafficTotal = 100;
                deviceView.node.trafficUsed = Math.random() * 100;
                if (deviceViews.indexOf(deviceView)==0) {
                    deviceView.node.trafficUsed = Math.random() * 10;
                }
                return deviceView;
            }).sort((a, b)=> {
                let aTrafficFree = a.node.trafficTotal - a.node.trafficUsed;
                let bTrafficFree = b.node.trafficTotal - b.node.trafficUsed;
                return aTrafficFree - bTrafficFree;
            });
            setDeviceViews(deviceViews);
        }
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    return (
        <DeviceNetworkTrafficWrapper>
            <Title>设备流量统计</Title>
            <Content>
                {
                    deviceViews.map((e)=> {
                        return <DeviceTrafficStatisticsItem key={e.device.id}>
                            <DeviceName>{e.device.name}</DeviceName>
                            <TrafficUsageBar>
                                <TrafficUsed style={{width: (e.node.trafficUsed / e.node.trafficTotal * 100.0) + '%'}}/>
                                <TrafficFree style={{width: ((e.node.trafficTotal - e.node.trafficUsed) / e.node.trafficTotal * 100.0) + '%'}}/>
                            </TrafficUsageBar>
                            <TrafficUsageText>
                                {e.node.trafficUsed.toFixed(2)} / {e.node.trafficTotal} MB
                            </TrafficUsageText>
                        </DeviceTrafficStatisticsItem>
                    })
                }
            </Content>
        </DeviceNetworkTrafficWrapper>
    )
};

export default DeviceNetworkTraffic;

