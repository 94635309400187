import { useEffect, useState } from "react";
import { Content, DeviceItem, DeviceListWrapper, DeviceName, DeviceRssi, DeviceRssiIcon, DeviceRssiText, DeviceRssiValue, DeviceStatus, DeviceTemplateName, OfflineStatus, OnlineStatus, StatusText, Title } from "./DeviceList.styled";
import DeviceApi from "../../../../../api/DeviceApi";
import EventManager from "../../common/EventManager";
import StringUtils from "../../../../../util/StringUtils";

interface DeviceListProprs {
    eventManager: EventManager
}

export const DeviceList: React.FC<DeviceListProprs> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // state
    let [deviceViews, setDeviceViews] = useState([]);

    // event
    let onDeviceSelected = (deviceView)=> {
        props.eventManager.notifyDeviceSelected(deviceView.device.id);
    };

    // onload
    useEffect(()=> {
        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            deviceViews = deviceViews.sort((a, b)=> {
                if (a.node.status != b.node.status) {
                    if (StringUtils.equalsIgnoreCase(a.node.status, 'ONLINE')) {
                        return -1;
                    }
                    if (StringUtils.equalsIgnoreCase(b.node.status, 'ONLINE')) {
                        return 1;
                    }
                }
            });
            setDeviceViews(deviceViews);
        }
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    let getDeviceStatus = (status: string)=> {
        if (status=='ONLINE') {
            return <OnlineStatus>
                <StatusText>在线</StatusText>
                <svg className="icon" aria-hidden="true" style={{color: '#F97D5B', fontSize: '18px'}}>
                    <use xlinkHref="#icon-online"></use>
                </svg>
            </OnlineStatus>;
        } else if(status=='OFFLINE') {
            return <OfflineStatus>
                <StatusText>离线</StatusText>
                <svg className="icon" aria-hidden="true" style={{color: '#F97D5B', fontSize: '18px'}}>
                    <use xlinkHref="#icon-offline"></use>
                </svg>
            </OfflineStatus>;
        }
        return 'N/A';
    };

    let getDeviceRssi = (deviceRssi: number)=> {        
        let signalStrengthColor = '';
        let signalStrength = 5;
        for(let [strength, rssi, color] of [
            [5, -60, '#1EA77B'],
            [4, -68, '#0092f5'],
            [3, -75, '#0092f5'],
            [2, -80, '#E70302'],
            [1, -90, '#E70302'],
        ]) {
            if (deviceRssi > (rssi as number)) {
                signalStrength = strength as any;
                signalStrengthColor = color as any;
                break;
            }
        }

        let blocks = [];
        for(let i=1; i<=5; i++) {
            blocks.push({
                margin: '0px 1px 0px 0px',
                width: '2px',
                height: (i / 5) * 100 + '%',
                backgroundColor: signalStrength < i ? 'rgba(255, 255, 255, 0.3)' : signalStrengthColor,
            });
        }


        return <DeviceRssiValue>
            <DeviceRssiText>{deviceRssi}dbm</DeviceRssiText>
            <DeviceRssiIcon>
                {
                    blocks.map((e, index)=> {
                        return <div style={e} key={index}>
                        </div>
                    })
                }
            </DeviceRssiIcon>
        </DeviceRssiValue>
    }

    return (
        <DeviceListWrapper>
            <Title>设备列表</Title>
            <Content>
                <DeviceItem style={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                    <DeviceName>设备名称</DeviceName>
                    <DeviceTemplateName>设备模板</DeviceTemplateName>
                    <DeviceStatus>设备状态</DeviceStatus>
                    <DeviceRssi>信号强度</DeviceRssi>
                </DeviceItem>
                {
                    deviceViews.map((e)=> {
                        return <DeviceItem key={e.device.id} onClick={()=> onDeviceSelected(e)}>
                            <DeviceName>{e.device.name}</DeviceName>
                            <DeviceTemplateName>{e.deviceTemplate.name}</DeviceTemplateName>
                            <DeviceStatus>{getDeviceStatus(e.node.status)}</DeviceStatus>
                            <DeviceRssi>{getDeviceRssi(e.latestHeartBeatMetric.rssi)}</DeviceRssi>
                        </DeviceItem>
                    })
                }
            </Content>
        </DeviceListWrapper>
    )
};
