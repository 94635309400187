import { useEffect, useState } from "react";
import SessionService from "../../../../services/session/SessionService";
import ViewStateService from "../../../../services/view-state/ViewStateService";
import { LeftWrapper, MainStatusBarWrapper, NotifyButton, NotifyIcon, RightWrapper, UserProfileButton, ViewTitle } from "./MainStatusBar.styled";
import { Popover } from "antd";
import TaskList from "./component/task-list/TaskList";

interface MainStatusBarProps {

}

const MainStatusBar: React.FC<MainStatusBarProps> = (props)=> {
    // current user profile
    let currentUserProfile = SessionService.getInstance().getCurrentUserProfile();

    // current title
    let [title, setTitle] = useState(ViewStateService.getInstance().getStatusBarTitle());
    let [openTaskList, setOpenTaskList] = useState(false);

    // effect
    useEffect(()=> {
        // load
        let statusBarTitleChangeListener = (statusBarTitle)=> {
            setTitle(statusBarTitle);
        }
        ViewStateService.getInstance().addStatusBarTitleChnageListener(statusBarTitleChangeListener);

        // unload
        return ()=> {
            ViewStateService.getInstance().removeStatusBarTitleChnageListener(statusBarTitleChangeListener);
        }
    }, []);

    return (
        <MainStatusBarWrapper>
            <LeftWrapper>
                <ViewTitle>{title}</ViewTitle>
            </LeftWrapper>
            <RightWrapper>
                <Popover placement="bottom" title="任务列表" content={<TaskList />} open={openTaskList} onOpenChange={(open)=> setOpenTaskList(open)}>
                    <NotifyButton onClick={()=> setOpenTaskList(true)}>
                        <svg className="icon" aria-hidden="true" style={{fontSize: '18px'}}>
                            <use xlinkHref="#icon-task"></use>
                        </svg>
                    </NotifyButton>
                </Popover>

                <NotifyButton>
                    <NotifyIcon className="iconfont icon-alarm" />
                </NotifyButton>

                <NotifyButton>
                    <NotifyIcon className="iconfont icon-notification" />
                </NotifyButton>

                <UserProfileButton>
                    {currentUserProfile.name.substring(0, 1)}
                </UserProfileButton>
            </RightWrapper>
        </MainStatusBarWrapper>
    )
}

export default MainStatusBar;

