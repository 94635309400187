import styled from "styled-components";

export const NavigatorWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 20px;
`;

export const NavigateItem = styled.a`
    font-size: 18px;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.5);
    padding: 0px 10px 5px 10px;
    margin: 0px 10px;
    cursor: pointer;
    transition: all 0.5s;
    border-bottom: 2px solid transparent;
    text-decoration: none;

    &.selected {
        color: rgba(255, 255, 255, 0.95);
        border-bottom: 2px solid rgba(255, 255, 255, 0.95);
    }

    &:hover {
        color: rgba(255, 255, 255, 0.95);
    }
`;
