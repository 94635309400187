import styled from "styled-components";

export const DeviceDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    padding: 20px 10px 10px 10px;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
`;

export const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 180px;
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 55px;
`;

export const InfoLabel = styled.span`
    font-size: 12px;
    opacity: 0.85;
`;

export const InfoValue = styled.span`
    font-size: 14px;
    margin-top: 4px;
`;
