import styled from "styled-components";

export const UserManagementWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ToolBarWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    padding: 0px 20px;
`;

export const ToolBarLeftWrapper = styled.div`
    flex: 0 0 auto;
`;

export const ToolBarRightWrapper = styled.div`
    flex: 0 0 auto;
`;


export const FilterItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .ant-select-selector {
        overflow: hidden;
    }

    & .ant-select-selection-overflow {
        flex-wrap: nowrap;
    }
`;

export const FilterItemLabel = styled.div`
    font-size: 11px;
    color: #333333;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    letter-spacing: 2px;
`;

export const TableWrapper = styled.div`
    flex: 1 1 auto;
    border-radius: 5px;
    overflow: hidden;
`;
