import { useEffect, useState } from "react";
import { DownloadLink, ExportDeviceMetricsTask, TaskInformation, TaskListWrapper, TaskName, TaskProgress } from "./TaskList.styled";
import { TaskStatus } from "../../../../../firmware-push-managment/view-task/FirmwarePushTaskDetails.styled";
import moment from "moment";
import { Progress } from "antd";
import DeviceMetricApi from "../../../../../../api/DeviceMetricApi";

interface TaskListProps {

}

let TaskList: React.FC<TaskListProps> = (props)=> {

    // api
    let deviceMetricApi = new DeviceMetricApi();

    // state
    let [tasks, setTasks] = useState([]);

    // function
    let loadTasks = async ()=> {
        let tasks = (await deviceMetricApi.getHistoricalDeviceMetricsExportTasks()).data.data;
        setTasks(tasks);
    };

    let getTaskName = (task)=> {
        let taskDateTime = moment(new Date(parseInt(task.createTime))).format('YYYY-MM-DD HH:mm:ss');
        return `${task.deviceName} 数据导出 ${taskDateTime}`;
    };

    let getDownloadLink = (task)=> {
        return `/device-metric/history-metrics/device/export-task/download?taskId=${task.id}`;
    };

    let getProgressPercent = (task)=> {
        if (task.total <= 0) {
            return 0;
        }
        return Math.floor(task.progress / task.total * 100.0);
    };

    let getProgressStatus = (task)=> {
        if (task.status == 'ERROR') {
            return 'exception';
        } else if(task.status == 'DONE') {
            return 'success';
        } else {
            return 'active';
        }
    }
    
    // effect
    useEffect(()=> {
        loadTasks();

        // refresh task
        let refreshInterval = window.setInterval(()=> {
            loadTasks();
        }, 5000);
        return ()=> {
            window.clearInterval(refreshInterval);
        }
    }, []);

    // render
    return <TaskListWrapper>
        {
            tasks.map((task)=> {
                return <ExportDeviceMetricsTask key={task.id}>
                    <TaskInformation>
                        <TaskName>{getTaskName(task)}</TaskName>
                        { task.status == 'DONE' && <DownloadLink href={getDownloadLink(task)}>下载文件</DownloadLink> }
                    </TaskInformation>

                    <TaskProgress>
                        <Progress
                            size="small"
                            percent={getProgressPercent(task)}
                            status={getProgressStatus(task) as any} 
                            showInfo={false}
                        />
                    </TaskProgress>
                </ExportDeviceMetricsTask>
            })
        }
    </TaskListWrapper>
};

export default TaskList;
