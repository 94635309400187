import React, { useRef } from "react";
import { DeivceOnlineDurationWrapper, DeviceAlarmStatisticsWrapper, DeviceAlarmsWrapper, DeviceListWrapper, DeviceMapWrapper, DeviceModel3DWrapper, DeviceOnlineStatisticsWrapper, DeviceTrafficStatisticsWrapper, OverviewBigScreenInnerWrapper, OverviewBigScreenWrapper, ScreenTitle, ScreenTitleBar, ScreenTitleBarLeft, ScreenTitleBarRight, ScreenTitleCn, ScreenTitleEn } from "./OverviewBigScreen.styled";
import { DeviceMap } from "./components/device-map/DeviceMap";
import { DeviceOnlineStatistics } from "./components/device-online-statistics/DeviceOnlineStatistics";
import { DeviceList } from "./components/device-list/DeviceList";
import { DeviceAlarms } from "./components/device-alarm/DeviceAlarms";
import { DeviceAlarmStatistics } from "./components/device-alarm-statistics/DeviceAlarmStatistics";
import { Clock } from "./components/clock/Clock";
import { Weather } from "./components/weather/Weather";
import EventManager from "./common/EventManager";
import DeviceNetworkTraffic from "./components/device-network-traffic/DeviceNetworkTraffix";
import Navigator from "./components/navigator/Navigator";
import DeviceOnlineDuration from "./components/device-online-duraation/DeviceOnlineDuration";

interface OverviewBigScreenProps {
    
}


export const OverviewBigScreen: React.FC<OverviewBigScreenProps> = (props)=> {
    // event manager
    let eventManagerRef = useRef(new EventManager());

    // render
    return (
        <OverviewBigScreenWrapper>
            <OverviewBigScreenInnerWrapper>
                <ScreenTitleBar>
                    <ScreenTitleBarLeft>
                        <Navigator selected="OVERVIEW" />
                    </ScreenTitleBarLeft>

                    <ScreenTitle>
                        <ScreenTitleCn>智能卷扬机互联系统</ScreenTitleCn>
                        <ScreenTitleEn>Winch Intelligent Link System</ScreenTitleEn>
                    </ScreenTitle>

                    <ScreenTitleBarRight>
                        <Weather />
                        <Clock />
                    </ScreenTitleBarRight>
                </ScreenTitleBar>

                <DeviceMapWrapper>
                    <DeviceMap eventManager={eventManagerRef.current}/>
                </DeviceMapWrapper>
                
                <DeviceOnlineStatisticsWrapper>
                    <DeviceOnlineStatistics />
                </DeviceOnlineStatisticsWrapper>

                <DeviceListWrapper>
                    <DeviceList eventManager={eventManagerRef.current}/>
                </DeviceListWrapper>

                <DeviceTrafficStatisticsWrapper>
                    <DeviceNetworkTraffic />
                </DeviceTrafficStatisticsWrapper>

                <DeviceAlarmStatisticsWrapper>
                    <DeviceAlarmStatistics />
                </DeviceAlarmStatisticsWrapper>

                <DeviceAlarmsWrapper>
                    <DeviceAlarms />
                </DeviceAlarmsWrapper>

                <DeivceOnlineDurationWrapper>
                    <DeviceOnlineDuration />
                </DeivceOnlineDurationWrapper>
            </OverviewBigScreenInnerWrapper>

        </OverviewBigScreenWrapper>
    )
};
