import { useEffect, useState } from "react";
import { Button, Input, Modal, Progress, Space, message } from "antd";
import { Table } from "antd";
import { ColumnType, TablePaginationConfig } from "antd/es/table";
import moment from "moment";
import { FirmwarePushManagementWrapper, TableWrapper, ToolBarLeftWrapper, ToolBarRightWrapper, ToolBarWrapper } from "./FirmwarePushManagement.styled";
import FirmwarePushTaskApi from "../../api/FirmwarePushTaskApi";
import { SearchOutlined } from "@ant-design/icons";
import CreateFirmwarePushTaskWizard from "./create-push-task/CreateFirmwarePushTaskWizard";
import FirmwarePushTaskDetails from "./view-task/FirmwarePushTaskDetails";
import FirmwarePushTaskUtils from "./util/FirmwarePushTaskUtils";
import StringUtils from "../../util/StringUtils";

interface FirmwarePushProps {

}

interface TableParams {
    pagination?: TablePaginationConfig;
}  

const FirmwarePushManagement: React.FC<FirmwarePushProps> = (props)=> {
    // api
    const firmwarePushTaskApi = new FirmwarePushTaskApi();
    const [messageApi, contextHolder] = message.useMessage();
    
    // state
    let [firmwarePushTaskViews, setFirmwarePushTaskViews] = useState([]);
    let [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);
    let [openFirmwarePushTaskDetailsModal, setOpenFirmwarePushTaskDetailsModal] = useState(false);
    let [openFirmwarePushTaskId, setOpenFirmwarePushTaskId] = useState(null);
    let [loading, setLoading] = useState(false);
    let [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    // functions
    let loadFirmwarePushTaskViews = async ()=> {
        // show loading spin
        setLoading(true);

        // fetch data
        let firmwarePushTaskViewsPage = (await firmwarePushTaskApi.queryTasks({
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        })).data.data;

        // update state
        setFirmwarePushTaskViews(firmwarePushTaskViewsPage.elements);
        tableParams.pagination.total = firmwarePushTaskViewsPage.totalElements;
        setTableParams({...tableParams});
        setLoading(false);
    };

    let viewTaskDetails = (firmwarePushTask)=> {
        setOpenFirmwarePushTaskId(firmwarePushTask.id);
        setOpenFirmwarePushTaskDetailsModal(true);
    };

    // event
    let onCreateTaskClick = ()=> {
        setOpenCreateTaskModal(true);
    };

    let handleTableChange = (pagination, filters, sorter) => {
        tableParams = {pagination};
        setTableParams(tableParams);
        loadFirmwarePushTaskViews();
    };

    // effect
    useEffect(()=> {
        loadFirmwarePushTaskViews();
    }, []);

    let getProgressPercent = (firmwarePushTask)=> {
        if (firmwarePushTask==null) {
            return 0;
        }
        
        let total = 0;
        let complete = 0;
        for(let nodeId in firmwarePushTask.nodeOtaStatusMap) {
            let nodeOtaStatus = firmwarePushTask.nodeOtaStatusMap[nodeId];
            if (nodeOtaStatus=='DONE' || nodeOtaStatus=='ERROR') {
                complete++;
            }
            total++;
        }
        return complete / total * 100.0;
    };

    let getSuccessPercent = (firmwarePushTask)=> {
        if (firmwarePushTask==null) {
            return 0;
        }
        
        let total = 0;
        let success = 0;
        for(let nodeId in firmwarePushTask.nodeOtaStatusMap) {
            let nodeOtaStatus = firmwarePushTask.nodeOtaStatusMap[nodeId];
            if (nodeOtaStatus=='DONE') {
                success++;
            }
            total++;
        }
        return success / total * 100.0;
    };

    // column definition
    let columns: ColumnType<any>[] = [
        {
            title: '状态',
            dataIndex: 'task.status',
            key: "task.status",
            align: 'center',
            render: (text, record, index)=> {
                return FirmwarePushTaskUtils.taskStatusName2Text(record.task.status);
            }
        },
        {
            title: '产品型号',
            dataIndex: 'firmware.productModel',
            key: 'firmware.productModel',
            align: 'center',
            render: (text, record, index)=> {
                return StringUtils.emptyToSlash(record.firmware?.productModel);
            }
        },
        {
            title: '固件版本',
            dataIndex: 'firmware.version',
            key: 'firmware.version',
            align: 'center',
            render: (text, record, index)=> {
                return StringUtils.emptyToSlash(record.firmware?.version);
            }
        },
        {
            title: '任务进度',
            dataIndex: 'task.progress',
            key: 'task.progress',
            align: 'center',
            render: (text, record, index)=> {
                return <Progress
                    percent={getProgressPercent(record.task)}
                    success={{percent: getSuccessPercent(record.task), strokeColor: '#389E0E'}}
                />
            }
        },
        {
            title: '创建任务时间',
            dataIndex: 'task.createTime',
            key: 'task.createTime',
            align: 'center',
            render: (text, record, index)=> {
                return moment(Number(record.task.createdTime)).format('YYYY/MM/DD HH:mm:ss');
            }
        },
        {
            title: '创建人',
            dataIndex: 'task.creator',
            key: 'task.createTime',
            align: 'center',
            render: (text, record, index)=> {
                return record.task.creator
            }
        },
        {
            title: '备注',
            dataIndex: 'task.remarks',
            key: 'task.remarks',
            align: 'center',
            render: (text, record, index)=> {
                return record.task.remarks;
            }
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return <Space size="middle">
                    <a onClick={()=> viewTaskDetails(record.task)}>查看详情</a>
                </Space>
            }
        }
    ];

    // render
    return (
        <FirmwarePushManagementWrapper>
            {contextHolder}
            <ToolBarWrapper>
                <ToolBarLeftWrapper>
                    <Space size="middle">
                        <Button type="primary" onClick={onCreateTaskClick}>新建任务</Button>
                    </Space>
                </ToolBarLeftWrapper>

                <ToolBarRightWrapper>
                    <Input placeholder="请输入设备名" suffix={<SearchOutlined />} size="large" />
                </ToolBarRightWrapper>

                <Modal open={openCreateTaskModal} 
                    onClose={()=> setOpenCreateTaskModal(false)} 
                    footer={null} 
                    closable={false}
                    width={1440}
                    height={800}
                    >
                    <CreateFirmwarePushTaskWizard 
                        onClose={()=> setOpenCreateTaskModal(false)} 
                        onComplete={(firmwarePushTask)=> {
                            loadFirmwarePushTaskViews();
                            viewTaskDetails(firmwarePushTask);
                        }}/>
                </Modal>

                { openFirmwarePushTaskDetailsModal && 
                    <Modal open={openFirmwarePushTaskDetailsModal} 
                        footer={null} 
                        closable={false}
                        width={800}
                        height={600}
                        onClose={()=> setOpenFirmwarePushTaskDetailsModal(false)} 
                        >
                        <FirmwarePushTaskDetails 
                            id={openFirmwarePushTaskId}
                            onClose={()=> setOpenFirmwarePushTaskDetailsModal(false)}
                            />
                    </Modal>
                }

            </ToolBarWrapper>
            <TableWrapper>
                <Table
                    rowKey={(record)=> record.task.id}
                    columns={columns} 
                    dataSource={firmwarePushTaskViews}
                    loading={loading}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                />
            </TableWrapper>
        </FirmwarePushManagementWrapper>
    )
};

export default FirmwarePushManagement;

