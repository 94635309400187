import { NavigateItem, NavigatorWrapper } from "./Navigator.styled";


interface NavigatorProps {
    selected?: string
}

let Navigator: React.FC<NavigatorProps> = (props)=> {
    return (
        <NavigatorWrapper>
            <NavigateItem>管理控制台</NavigateItem>
            <NavigateItem href="/#/big-screen/overview" className={props.selected=='OVERVIEW' ? "selected" : "" }>系统总览</NavigateItem>
            <NavigateItem className={props.selected=='DEVICE_DETAILS' ? "selected" : "" }>设备详情</NavigateItem>
        </NavigatorWrapper>
    )
};

export default Navigator;
