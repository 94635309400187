

import styled from "styled-components";

export const DeviceOnlineDurationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
`;

export const Title = styled.div`
    font-size: 18px;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 0px;
`;

export const DeviceOnlineDurationItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: rgba(180, 180, 180, 0.2);
    }
`;

export const DeviceName = styled.div`
    flex: 0 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
`;

export const OnlineDurationBar = styled.div`
    flex: 2 2 0px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin: 0px 10px;
`;

export const OnlineDurationUsed = styled.div`
    height: 20px;
    background-color: #91CB74;
    opacity: 1.0;
`;

export const OnlineDurationText = styled.div`
    flex: 0 0 auto;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
`;
