
class StringUtils {

    public static isEmpty(value: string): boolean {
        if (value==null || (value+'').trim().length <=0) {
            return true;
        }
        return false;
    }

    public static nonull(value: any): string {
        if(value==null) {
            return '';
        }
        return value + '';
    }

    public static equalsIgnoreCase(a: string, b: string): boolean {
        if (a==b) {
            return true;
        }
        if (a==null || b==null) {
            return false;
        }

        return a.toLowerCase()==b.toLowerCase();
    }

    public static emptyToSlash(value: string): string {
        return this.isEmpty(value)? 'N/A' : value;
    }

    public static emptyTo0(value: string): string {
        return this.isEmpty(value)? '0' : value;
    }
}

export default StringUtils;
