import styled from "styled-components";

export const WeatherWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
`;

export const City = styled.div`
    font-size: 18px;
    padding: 0px 10px;
    white-space: nowrap;
`;

export const WeatherIcon = styled.div`
    font-size: 48px;
    margin-bottom: 5px;
`;

export const Temperature = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 0px 10px;
`;

export const TemperatureUnit = styled.div`
    font-size: 18px;
`;
