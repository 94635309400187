interface DeviceMetricsManagementProps {

}

const DeviceMetricsManagement: React.FC<DeviceMetricsManagementProps> = (props)=> {
    // render
    return (
        <div>Device Metrics Management, Developing...</div>
    );
}

export default DeviceMetricsManagement;
