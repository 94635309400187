import styled from "styled-components";

export const MainStatusBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
`;

export const LeftWrapper = styled.div`
    display: flex;

`;

export const ViewTitle = styled.div`
    font-size: 16px;
    color: #4F4F4F;
    vertical-align: middle;
`;

export const RightWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const NotifyButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    border: 1px solid #CECECE;
    margin: 0px 10px;

    &:hover {
        background-color: #CECECE;
    }
`;

export const NotifyIcon = styled.span`
    font-size: 18px;
    height: 18px;
`;

export const UserProfileButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0px 10px 0px 20px;
    border-radius: 50%;
    padding-bottom: 3px;
    box-sizing: border-box;
    background-color: #101010;
    color: #FEFEFE;
    font-size: 22px;
    text-align: center;
    cursor: pointer;
`;

