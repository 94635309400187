import { Button, Input, Modal, Popconfirm, Space, Table, TablePaginationConfig, message } from "antd";
import { useNavigate } from "react-router-dom";
import { ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import moment from "moment";

import UserApi from "../../api/UserApi";
import CreateUserWizard from "./components/CreateUserWizard";
import StringUtils from "../../util/StringUtils";
import { FilterItem, FilterItemLabel, TableWrapper, ToolBarLeftWrapper, ToolBarRightWrapper, ToolBarWrapper, UserManagementWrapper } from "./UserManagement.styled";

interface UserManagementProps {

}

interface TableParams {
    pagination?: TablePaginationConfig;
}

const UserManagement: React.FC<UserManagementProps> = (props)=> {
    // api
    let userApi = new UserApi();
    const [messageApi, contextHolder] = message.useMessage();
    let navigate = useNavigate();

    // state
    let [users, setUsers] = useState([]);
    let [filterUsers, setFilterUsers] = useState([]);
    let [editingUser, setEditingUser] = useState(null);
    let [openUserModal, setOpenUserModal] = useState(false);

    let [loading, setLoading] = useState(true);
    let [search, setSearch] = useState("");
    let [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });

    // events
    let viewUserDetails = (user: any)=> {
        setEditingUser(user);
        setOpenUserModal(true);
    };

    let onSearchChange = (e)=> {
        let search = e.target.value;
        setSearch(search);
        
        let filterUsers = [].concat(users);
        if (!StringUtils.isEmpty(search)) {
            filterUsers = users.filter((user)=> {
                if (user.name.indexOf(search) >= 0) {
                    return true;
                }
                if (user.email.indexOf(search) >= 0) {
                    return true;
                }
                if (user.phoneNumber.indexOf(search) >= 0) {
                    return true;
                }
                return false;
            });
        }
        setFilterUsers(filterUsers);
    }

    let onTableChange = (pagination)=> {
        tableParams.pagination = pagination;
        setTableParams({pagination: pagination});
        loadUsers();
    };

    let onCreateUserClick = ()=> {
        setEditingUser(null);
        setOpenUserModal(true);
    };

    let onDeleteUser = async (user: any)=> {
        await userApi.delete(user.id);
        messageApi.open({
            type: 'success',
            content: '删除用户成功',
          });
        loadUsers();
    }

    // load device views
    let loadUsers = async ()=> {
        // loading animation
        setLoading(true);
        let users = (await userApi.getAll()).data.data;
        setUsers(users);
        setFilterUsers([].concat(users));
        setLoading(false);
    };

    let getUserStatusText = (user)=> {
        return user.disabled ? '禁用' : '正常';
    };

    // column definition
    let columns: ColumnType<any>[] = [
        {
            title: '用户名',
            dataIndex: 'user.name',
            key: 'user.name',
            align: 'center',
            render: (text, record, index)=> {
                return <a onClick={()=> viewUserDetails(record)}>{record.name}</a>
            }
        },
        {
            title: '电子邮箱',
            dataIndex: 'user.email',
            key: 'user.email',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(record.email)}</span>
            }
        },
        {
            title: '电话号码',
            dataIndex: 'user.phoneNumber',
            key: 'user.phoneNumber',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(record.phoneNumber)}</span>
            }
        },
        {
            title: '最后登录时间',
            dataIndex: 'user.lastLoginTime',
            key: 'user.lastLoginTime',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{ moment(parseInt(record.lastLoginTime)).format("YYYY-MM-DD HH:mm:ss") }</span>
            }
        },
        {
            title: '状态',
            dataIndex: 'user.disabled',
            key: "user.disabled",
            align: 'center',
            render: (text, record, index)=> {
                return getUserStatusText(record);
            }
        },
        {
            title: '备注',
            dataIndex: 'user.remarks',
            key: 'user.remarks',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(record.remarks)}</span>
            }
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return <Space size="middle">
                    <a onClick={()=> viewUserDetails(record)}>查看详情</a>
                    { record.name != 'admin' && <Popconfirm
                            title="删除用户"
                            description={ <div style={{paddingTop: "10px", paddingBottom: '10px'}}>确认要此用户删除么?</div> }
                            onConfirm={()=> onDeleteUser(record)}
                            okText="确定"
                            cancelText="取消"
                        > 
                        <a>删除用户</a>
                    </Popconfirm> }
                </Space>
            }
        }
    ];

    // effect
    useEffect(()=> {
        loadUsers();
    }, []);

    // render
    return (
        <UserManagementWrapper>
            {contextHolder}
            <ToolBarWrapper>
                <ToolBarLeftWrapper>
                    <Space size="middle">
                        <Button type="primary" onClick={onCreateUserClick}>新增用户</Button>
                        <Button type="primary" danger={true}>批量删除</Button>
                    </Space>
                </ToolBarLeftWrapper>

                <ToolBarRightWrapper>
                    <FilterItem>
                        <FilterItemLabel>搜索</FilterItemLabel>
                        <Input 
                            placeholder="请输入关键字" 
                            suffix={<SearchOutlined />} 
                            size="large" 
                            value={search} 
                            onChange={(e)=> onSearchChange} 
                        />
                    </FilterItem>
                </ToolBarRightWrapper>

                <Modal open={openUserModal} 
                    onClose={()=> setOpenUserModal(false)} 
                    footer={null} 
                    closable={false}
                    width={1000}
                    height={600}
                    >
                    <CreateUserWizard onClose={()=> setOpenUserModal(false)} onComplete={()=> loadUsers()} editingUserId={editingUser?.id} />
                </Modal>
            </ToolBarWrapper>
            <TableWrapper>
                <Table
                    rowKey={(record)=> record.id}
                    columns={columns} 
                    dataSource={users}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={onTableChange}
                />
            </TableWrapper>
        </UserManagementWrapper>
    );
}

export default UserManagement;
