import { useEffect, useState } from "react";
import { ClockWrapper, DateWrapper, TimeWrapper } from "./Clock.styled";
import moment from "moment";

interface ClockProps {

}

export const Clock: React.FC<ClockProps> = (props)=> {

    // state
    let [now, setNow] = useState(new Date());

    // effect
    useEffect(()=> {
        let refreshInterval = setInterval(()=> {
            setNow(new Date());
        }, 1000);

        return ()=> {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        }
    }, []);

    // render
    return (
        <ClockWrapper>
            <TimeWrapper>{moment(now).format('HH:mm:ss')}</TimeWrapper>
            <DateWrapper>{moment(now).format('YYYY/MM/DD')}</DateWrapper>
        </ClockWrapper>
    )
};
