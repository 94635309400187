import styled from "styled-components"

export const TaskListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ExportDeviceMetricsTask = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TaskInformation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 9px;
`;

export const TaskName = styled.div`

`;

export const DownloadLink = styled.a`

`;

export const TaskProgress = styled.div`

`;