import { useEffect, useState } from "react";
import DeviceApi from "../../../../../api/DeviceApi";
import { Content, DeviceName, DeviceOnlineDurationItem, DeviceOnlineDurationWrapper, OnlineDurationBar, OnlineDurationText, OnlineDurationUsed, Title } from "./DeviceOnlineDuration.styled";
import TimeUtils from "../../../../../util/TimeUtils";
import StringUtils from "../../../../../util/StringUtils";

interface DeviceOnlineDurationProps {

}

let DeviceOnlineDuration: React.FC<DeviceOnlineDurationProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // state
    let [deviceViews, setDeviceViews] = useState([]);

    // onload
    useEffect(()=> {
        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            deviceViews = deviceViews.map((e)=> {
                e.node.totalOnlineTime = e.node.totalOnlineTime == null ? 0 : e.node.totalOnlineTime;
                e.node.onlineDuration = parseInt(e.node.totalOnlineTime);
                return e;
            }).sort((a, b)=> {
                let aOnlineTime = a.node.onlineTime;
                let bOnlineTime = b.node.onlineTime;
                return aOnlineTime - bOnlineTime;
            });
            setDeviceViews(deviceViews);
        }
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render

    let getOnlineDurationUsedPercent = (deviceView)=> {
        let maxOnlineTime = deviceViews[0].node.onlineDuration;
        for(let fullOnlineTime of [
            1000 * 60 * 10,
            1000 * 60 * 30,
            1000 * 60 * 60,
            1000 * 60 * 60 * 24,
            1000 * 60 * 60 * 24 * 30,
        ]) {
            if (fullOnlineTime > maxOnlineTime) {
                let percent = (deviceView.node.onlineDuration / fullOnlineTime) * 100;
                percent = percent <= 1 ? 1 : percent;
                return percent;
            }
        }
        return 100;
    }

    return (
        <DeviceOnlineDurationWrapper>
            <Title>设备在线时间统计</Title>
            <Content>
                {
                    deviceViews.map((e)=> {
                        return <DeviceOnlineDurationItem key={e.device.id}>
                            <DeviceName>{e.device.name}</DeviceName>
                            <OnlineDurationBar>
                                <OnlineDurationUsed style={{width: getOnlineDurationUsedPercent(e) + '%'}}/>
                            </OnlineDurationBar>
                            <OnlineDurationText>
                                {TimeUtils.durationToText(e.node.onlineDuration)}
                            </OnlineDurationText>
                        </DeviceOnlineDurationItem>
                    })
                }
            </Content>
        </DeviceOnlineDurationWrapper>
    )
};

export default DeviceOnlineDuration;
