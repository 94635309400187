import { useParams } from "react-router-dom";
import { Clock } from "../overview/components/clock/Clock";
import Navigator from "../overview/components/navigator/Navigator";
import { Weather } from "../overview/components/weather/Weather";
import { DetailsDetailsWrapper, DeviceAlarmsWrapper, DeviceDetailsContent, DeviceDetailsScreenInnerWrapper, DeviceDetailsScreenWrapper, DeviceMetricsWrapper, DeviceModel3DWrapper, DeviceTrackWrapper, ScreenTitle, ScreenTitleBar, ScreenTitleBarLeft, ScreenTitleBarRight, ScreenTitleCn, ScreenTitleEn } from "./DeviceDetailsScreen.styled";
import DeviceModel3D from "./components/device-model-3d/DeviceModel3D";
import DeviceTrack from "./components/device-track/DeviceTrack";
import DeviceDetails from "./components/device-details/DeviceDetails";
import { DeviceAlarms } from "./components/device-alarms/DeviceAlarms";
import DeviceMetrics from "./components/device-metrics/DeviceMetrics";

interface DeviceDetailsScreenProps {

}

let DeviceDetailsScreen: React.FC<DeviceDetailsScreenProps> = (props)=> {
    // parameters
    let params = useParams();
    let { deviceId } = useParams();

    // render
    return (
        <DeviceDetailsScreenWrapper>
            <DeviceDetailsScreenInnerWrapper>
                <ScreenTitleBar>
                    <ScreenTitleBarLeft>
                        <Navigator selected="DEVICE_DETAILS" />
                    </ScreenTitleBarLeft>

                    <ScreenTitle>
                        <ScreenTitleCn>智能卷扬机互联系统</ScreenTitleCn>
                        <ScreenTitleEn>Winch Intelligent Link System</ScreenTitleEn>
                    </ScreenTitle>

                    <ScreenTitleBarRight>
                        <Weather />
                        <Clock />
                    </ScreenTitleBarRight>
                </ScreenTitleBar>

                <DeviceModel3DWrapper>
                    <DeviceModel3D />
                </DeviceModel3DWrapper>

                <DeviceDetailsContent>
                    <DetailsDetailsWrapper>
                        <DeviceDetails deviceId={deviceId} />
                    </DetailsDetailsWrapper>

                    <DeviceTrackWrapper>
                        <DeviceTrack deviceId={deviceId} />
                    </DeviceTrackWrapper>
                    
                    <DeviceMetricsWrapper>
                        <DeviceMetrics deviceId={deviceId} />
                    </DeviceMetricsWrapper>
                </DeviceDetailsContent>
                
            </DeviceDetailsScreenInnerWrapper>

        </DeviceDetailsScreenWrapper>
    )
};

export default DeviceDetailsScreen;

