import styled from "styled-components";

export const ClockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const TimeWrapper = styled.div`
    font-size: 22px;
`;

export const DateWrapper = styled.div`
    font-size: 16px;
`;
