import { Table, Button, Input, Modal, Space, message, Form, Select, InputNumber, AutoComplete, Popconfirm } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import StringUtils from "../../../../../../util/StringUtils";
import { DeviceVariablesWrapper, EditVariableFormLayout, EditVariableFormRow, EditVariableWrapper, OperationWrapper, TableWrapper, Title, TitleWrapper, ToolBarLeftWrapper, ToolBarRightWrapper, ToolBarWrapper } from "./DeviceVariables.styled";
import DeviceTemplateApi from "../../../../../../api/DeviceTemplateApi";
import { ColumnType } from "antd/es/table";
import UnitUtils from "../../../../util/UnitUtils";
import ProductApi from "../../../../../../api/ProductApi";
import DeviceTemplateUtils from "../../../../util/DeviceTemplateUtils";

interface DeviceVariableProps {
    deviceTemplateView: any
}

let DeviceVariables: React.FC<DeviceVariableProps> = (props)=> {
    // params   
    let deviceTemplateView = props.deviceTemplateView;

    let getDeviceVariables = (deviceTemplateView)=> {
        let configurationJson = deviceTemplateView.deviceTemplate.configuration;
        let configuration = JSON.parse(configurationJson ? configurationJson : '{}');
        let variables = configuration.properties;
        if (variables == null) {
            variables = [];
            configuration.properties = variables;
            deviceTemplateView.deviceTemplate.configuration = JSON.stringify(configuration);
        }
        return variables;
    };
    
    //api
    const [messageApi, contextHolder] = message.useMessage();
    let deviceTemplateApi = new DeviceTemplateApi();
    let productApi = new ProductApi();

    // state
    let [openEditVariableModal, setOpenEditVariableModal] = useState(false);
    let [editingVariable, setEditingVariable] = useState(null as any);
    let [editVariableMode, setEditVariableMode] = useState(null as any);
    let [product, setProduct] = useState(null as any);
    let [variables, setVariables] = useState(getDeviceVariables(deviceTemplateView));
    let [search, setSearch] = useState('');

    // functions
    let saveVariables = ()=> {
        // re-render
        setVariables([].concat(variables));

        // update configuration
        let configurationJson = deviceTemplateView.deviceTemplate.configuration;
        let configuration = JSON.parse(configurationJson ? configurationJson : '{}');
        configuration.properties = variables;
        deviceTemplateView.deviceTemplate.configuration = JSON.stringify(configuration);

        // do save
        deviceTemplateApi.saveConfiguration(deviceTemplateView.deviceTemplate.id, configuration);

        // show success
        messageApi.open({
            type: 'success',
            content: '保存成功',
        });
    };

    let editVariable = (variable)=> {
        let variableIndex = variables.indexOf(editingVariable);
        if (variableIndex < 0) {
            return;
        }
        variables[variableIndex] = variable;
        saveVariables();
    };

    let addVariable = (variable)=> {
        variables.push(variable);
        saveVariables();
    };

    let getFilterVariables = ()=> {
        if (!search) {
            return variables;
        }
        let filterVariables = variables.filter((e)=> {
            return e.name.indexOf(search) >=0 || e.key.indexOf(search) >= 0;
        });
        return filterVariables;
    };

    let onAddVariable = ()=> {
        setEditVariableMode('CREATE');
        setEditingVariable(null);
        setOpenEditVariableModal(true);
    };

    let onEditVariable = (variable)=> {
        setEditVariableMode('EDIT');
        setEditingVariable(variable);
        setOpenEditVariableModal(true);
    }

    let onDeleteVariable = (variable)=> {
        let index = variables.indexOf(variable);
        if (index < 0) {
            return;
        }
        variables.splice(index, 1);
        saveVariables();
    };

    let onSaveVariable = (variable)=> {
        if (editVariableMode == 'CREATE') {
            addVariable(variable);
        } else {
            editVariable(variable);
        }
    };

    let onSearchChange = (e)=> {
        setSearch(e.target.value);
    };

    let onMoveUp = (variable)=> {
        let variableIndex = variables.indexOf(variable);
        if (variableIndex <= 0) {
            return;
        }
        variables[variableIndex] = variables[variableIndex-1];
        variables[variableIndex-1] = variable;
        setVariables([].concat(variables));
        saveVariables();
    }

    
    let onMoveDown = (variable)=> {
        let variableIndex = variables.indexOf(variable);
        if (variableIndex < 0 || variableIndex >= variables.length) {
            return;
        }
        variables[variableIndex] = variables[variableIndex+1];
        variables[variableIndex+1] = variable;
        setVariables([].concat(variables));
        saveVariables();
    }

    // column definition
    let columns: ColumnType<any>[] = [
        {
            title: '变量名称',
            dataIndex: 'variable.name',
            key: 'variable.name',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.name}</span>
            }
        },
        {
            title: '变量标识',
            dataIndex: 'variable.key',
            key: 'variable.key',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.key}</span>
            }
        },
        {
            title: '寄存器',
            dataIndex: 'variable.registerAddress',
            key: 'variable.registerAddress',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.registerAddress}</span>
            }
        },
        {
            title: '备注',
            dataIndex: 'variable.remarks',
            key: 'variable.remarks',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(record.remarks)}</span>
            }
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return <Space size="middle">
                    <a onClick={()=> onEditVariable(record)}>编辑变量</a>
                    <Popconfirm
                            title="删除变量"
                            description={ <div style={{padding: '10px 40px 10px 0px'}}>确认要删除变量么?</div> }
                            onConfirm={()=> onDeleteVariable(record)}
                            okText="确定"
                            cancelText="取消"
                        > 
                        <a>删除变量</a>
                    </Popconfirm>
                    <a onClick={()=> onMoveUp(record)}>上移</a>
                    <a onClick={()=> onMoveDown(record)}>下移</a>
                </Space>
            }
        }
    ];

    // effect
    useEffect(()=> {
        let loadProduct = async ()=> {
            let product = (await productApi.getProductByModel(deviceTemplateView.deviceTemplate.productModel)).data.data;
            setProduct(product);
        };
        loadProduct();
    }, []);

    // render
    return <DeviceVariablesWrapper>
        {contextHolder}

        <ToolBarWrapper>
            <ToolBarLeftWrapper>
                <Space size="middle">
                    <Button type="primary" onClick={onAddVariable}>添加变量</Button>
                </Space>
            </ToolBarLeftWrapper>

            <ToolBarRightWrapper>
                <Input placeholder="请输入变量名称" suffix={<SearchOutlined />} size="large" onChange={onSearchChange} />
            </ToolBarRightWrapper>

            <Modal open={openEditVariableModal} 
                onClose={()=> setOpenEditVariableModal(false)} 
                footer={null} 
                closable={false}
                width={1000}
                height={600}
                >
                { openEditVariableModal && <EditVariable 
                    product={product}
                    deviceTemplate={deviceTemplateView.deviceTemplate}
                    variable={editingVariable}
                    create={editVariableMode == 'CREATE' ? true : false}
                    onSave={(variable)=> onSaveVariable(variable)}
                    onClose={()=> setOpenEditVariableModal(false)}
                    /> 
                }
            </Modal>
        </ToolBarWrapper>
        <TableWrapper>
            <Table
                rowKey={(record)=> record.name}
                columns={columns} 
                dataSource={getFilterVariables()}
            />
        </TableWrapper>

    </DeviceVariablesWrapper>
};

interface EditVariableProps {
    product: any,
    deviceTemplate: any,
    variable: any,
    create: boolean;

    onClose: ()=> void,
    onSave: (variable: any)=> void,
}

let EditVariable: React.FC<EditVariableProps> = (props)=> {
    const product = props.product;
    const deviceTemplate = props.deviceTemplate;
    const [form] = Form.useForm();

    // state
    let [variable, setVariable] = useState(props.variable ?? {});
    let [canSave, setCanSave] = useState(false);

    // options
    let dataTypeOptions = [
        {label: 'INT32', value: 'INT32'},
        {label: 'INT64', value: 'INT64'},
        {label: 'FLOAT32', value: 'FLOAT32'},
        {label: 'FLOAT64', value: 'FLOAT64'},
        {label: 'BOOL', value: 'BOOL'},
    ];

    let unitOptions = UnitUtils.getPredefinedUnits().map((e)=> {
        return {
            label: `${e.symbol} ${e.name}`,
            value: e.symbol,
        };
    });

    let specification = JSON.parse(product.specification);
    let productResources = specification.productResources;
    let registerTypeOptions = [];
    for(let productResource of productResources) {
        if (productResource.type=='ADC_CHANNEL') {
            registerTypeOptions.push({
                label: 'ADC',
                value: 'ADC',
            });
        } else if(productResources.type=='UART') {
            // need protocol selection
        }
    }

    let adcProductResource = null;
    for(let productResource of productResources) {
        if (productResource.type=='ADC_CHANNEL') {
            adcProductResource = productResource;
            break;
        }
    }
    let adcRegisterAddressOptions = [];
    for(let i=0; i<adcProductResource.quantity; i++) {
        adcRegisterAddressOptions.push({
            label: `通道${i+1}`,
            value: `ch${i+1}`,
        });
    }

    // validate
    let validateForm = ()=> {
        setVariable({...variable});
        form.validateFields().then((values)=> {
            setCanSave(true);
        }).catch((err)=> {
            setCanSave(false);
        });
    }

    // events
    let onSave = (e)=> {
        props.onSave(variable);
        props.onClose();
    };

    let onCancel = (e)=> {
        props.onClose();
    };

    let onVariableNameChange = (e)=> {
        variable.name = e.target.value;
        validateForm();
    };

    let onVariableKeyChange = (e)=> {
        variable.key = e.target.value;
        validateForm();
    };

    let onDataTypeChange = (selectedValue)=> {
        variable.dataType = selectedValue;
        validateForm();
    };

    let onScaleChange = (value)=> {
        variable.scale=value;
        validateForm();
    };

    let onUnitChange = (value)=> {
        variable.unit=value;
        validateForm();
    };

    let onMinChange = (value)=> {
        variable.min = parseFloat(value);
        validateForm();
    };
    
    let onMaxChange = (value)=> {
        variable.max = parseFloat(value);
        validateForm();
    };

    let onRegisterTypeChange = (selectedValue)=> {
        if (variable.registerType != selectedValue) {
            variable.registerAddress = null;
        }
        variable.registerType = selectedValue;
        validateForm();
    };

    let onRegisterAddressChange = (selectedValue)=> {
        variable.registerAddress = selectedValue;
        validateForm();
    };

    let onLinearMappingX1Change = (e)=> {
        if (variable.linearMapping == null) {
            variable.linearMapping = {};
        }
        variable.linearMapping.x1 = e.target.value;
        validateForm();
    };

    let onLinearMappingY1Change = (e)=> {
        if (variable.linearMapping == null) {
            variable.linearMapping = {};
        }
        variable.linearMapping.y1 = e.target.value;
        validateForm();
    };

    let onLinearMappingX2Change = (e)=> {
        if (variable.linearMapping == null) {
            variable.linearMapping = {};
        }
        variable.linearMapping.x2 = e.target.value;
        validateForm();
    };

    let onLinearMappingY2Change = (e)=> {
        if (variable.linearMapping == null) {
            variable.linearMapping = {};
        }
        variable.linearMapping.y2 = e.target.value;
        validateForm();
    };

    let onMappingExpressionChange = (e)=> {
        variable.mappingExpression = e.target.value;
        validateForm();
    };

    let onRemarksChange = (e)=> {
        variable.remarks = e.target.value;
        validateForm();
    };
    
    let variableNameValidator = (rule, value, callback)=> {
        let nameDuplicated = false;
        let variables = deviceTemplate.variables;
        if (variables == null) {
            variables = [];
        }
        for(let existingVariable of variables) {
            if (existingVariable == variable) {
                continue;
            }
            if (existingVariable.name == value) {
                nameDuplicated = true;
                break;
            }
        }

        if (nameDuplicated) {
            callback("变量名称重复");
        } else {
            callback();
        }
    };
        
    let variableKeyValidator = (rule, value, callback)=> {
        let nameDuplicated = false;
        let variables = deviceTemplate.variables;
        if (variables == null) {
            variables = [];
        }
        for(let existingVariable of variables) {
            if (existingVariable == variable) {
                continue;
            }
            if (existingVariable.key == value) {
                nameDuplicated = true;
                break;
            }
        }

        if (nameDuplicated) {
            callback("变量标识重复");
        } else {
            callback();
        }
    };

    let getLinearMappingValidator = (key)=> {
        let linearMappingValidator = (rule, value, callback)=> {
            let linearMapping = variable.linearMapping;
            if (linearMapping == null) {
                linearMapping = {};
            }
    
            let {x1, y1, x2, y2} = linearMapping;
            if (x1 != null || y1!=null || x2 != null || y2 != null) {
                if (StringUtils.isEmpty(value)) {
                    callback(`${key} 不能为空`);
                } else if (x1==x2) {
                    callback('x1, x2 不能相同');
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        return linearMappingValidator;
    };

    useEffect(()=> {
        setVariable(props.variable ?? {});
    }, [props.variable, props.create]);

    // render
    return (
        <EditVariableWrapper>
            <TitleWrapper>
                <Title>新增变量</Title>
            </TitleWrapper>

            <Form form={form} name="form" layout="vertical">
                <EditVariableFormLayout>
                    <EditVariableFormRow>
                        <Form.Item name="name" label="变量名称" rules={[{required: true}, {validator: variableNameValidator}]} initialValue={variable.name}>
                            <Input onChange={onVariableNameChange} />
                        </Form.Item>

                        <Form.Item name="key" label="变量标识" rules={[{required: true}, {validator: variableKeyValidator}]} initialValue={variable.key}>
                            <Input onChange={onVariableKeyChange} />
                        </Form.Item>
                    </EditVariableFormRow>

                    <EditVariableFormRow>
                        <Form.Item name="dataType" label="数值类型" rules={[{required: true}]} initialValue={variable.dataType} style={{flex: '2 2 16.67%'}}>
                            <Select
                                placeholder="请选择数值类型"
                                onChange={onDataTypeChange}
                                options={dataTypeOptions}
                            />
                        </Form.Item>

                        <Form.Item name="scale" label="小数位数" rules={[{required: true}]} initialValue={variable.scale} style={{flex: '2 2 16.67%'}}>
                            <InputNumber onChange={onScaleChange} min={0} max={10} style={{width: '100%'}} />
                        </Form.Item>

                        <Form.Item name="unit" label="单位" rules={[{required: false}]} initialValue={variable.unit} style={{flex: '2 2 16.67%'}}>
                            <AutoComplete
                                options={unitOptions}
                                placeholder="请输入单位"
                                filterOption={(inputValue, option) =>
                                    StringUtils.nonull(option.value).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    || StringUtils.nonull(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onChange={onUnitChange}
                            />
                        </Form.Item>


                        <Form.Item name="min" label="最小值" rules={[{required: false}]} initialValue={variable.min} style={{flex: '3 3 25%'}}>
                            <InputNumber onChange={onMinChange} style={{width: '100%'}} />
                        </Form.Item>

                        <Form.Item name="max" label="最小值" rules={[{required: false}]} initialValue={variable.max} style={{flex: '3 3 25%'}}>
                            <InputNumber onChange={onMaxChange} style={{width: '100%'}} />
                        </Form.Item>

                    </EditVariableFormRow>

                    <EditVariableFormRow>
                        <Form.Item name="registerType" label="寄存器类型" rules={[{required: true}]} initialValue={variable.registerType}>
                            <Select
                                placeholder="请选择寄存器类型"
                                onChange={onRegisterTypeChange}
                                options={registerTypeOptions}
                            />
                        </Form.Item>

                        <Form.Item name="registerAddress" label="寄存器地址" rules={[{required: true}]} initialValue={variable.registerAddress}>
                            {
                                (()=> {
                                    if (variable.registerType == 'ADC') {
                                        return <Select
                                            placeholder="请选择ADC通道"
                                            onChange={onRegisterAddressChange}
                                            options={adcRegisterAddressOptions}
                                        />
                                    } else {
                                        return <Input onChange={(e)=> onRegisterAddressChange(e.target.value)} />
                                    }
                                })()
                            }
                        </Form.Item>
                    </EditVariableFormRow>

                    <EditVariableFormRow>
                        <Form.Item name="linearMapping.X1" label="线性映射 X1" rules={[{required: false}, {validator: getLinearMappingValidator('x1')}]} initialValue={variable.linearMapping?.x1}>
                            <Input onChange={onLinearMappingX1Change} prefix={<span>X1</span>} />
                        </Form.Item>

                        <Form.Item name="linearMapping.Y1" label="Y1" rules={[{required: false}, {validator: getLinearMappingValidator('y1')}]} initialValue={variable.linearMapping?.y1}>
                            <Input onChange={onLinearMappingY1Change} prefix={<span>Y1</span>} />
                        </Form.Item>

                        <Form.Item name="linearMapping.X2" label="X2" rules={[{required: false}, {validator: getLinearMappingValidator('x2')}]} initialValue={variable.linearMapping?.x2}>
                            <Input onChange={onLinearMappingX2Change} prefix={<span>X2</span>} />
                        </Form.Item>

                        <Form.Item name="linearMapping.Y2" label="Y2" rules={[{required: false}, {validator: getLinearMappingValidator('y2')}]} initialValue={variable.linearMapping?.y2}>
                            <Input onChange={onLinearMappingY2Change} prefix={<span>Y2</span>} />
                        </Form.Item>
                    </EditVariableFormRow>

                    <EditVariableFormRow>
                        <Form.Item name="mappingExpression" label="自定义数据映射脚本" rules={[{required: false}]} initialValue={variable.mappingExpression}>
                            <Input.TextArea style={{height: '100px'}} onChange={onMappingExpressionChange}/>
                        </Form.Item>
                    </EditVariableFormRow>
                    
                    <EditVariableFormRow>
                        <Form.Item name="remarks" label="备注" rules={[{required: false}]} initialValue={variable.remarks}>
                            <Input.TextArea style={{height: '50px'}} onChange={onRemarksChange}/>
                        </Form.Item>
                    </EditVariableFormRow>

                </EditVariableFormLayout>
            </Form>

            <OperationWrapper>
                <Space size="middle">
                    <Button type="primary" disabled={!canSave} onClick={onSave}>确定</Button>
                    <Button type="primary" danger onClick={onCancel}>取消</Button>
                </Space>
            </OperationWrapper>
        </EditVariableWrapper>
    )
};

export default DeviceVariables;

