import styled from "styled-components";

let titleBarHeight = 80;

export const DeviceDetailsScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #FFFFFF;
    background-color: #000000;
    letter-spacing: 2px;
`;

export const DeviceDetailsScreenInnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 850px;
    color: #FFFFFF;
    letter-spacing: 2px;
`;

export const DeviceMapWrapper = styled.div`
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
`;

export const ScreenTitleBar = styled.div`
    position: absolute;
    z-index: 100;
    left: 0%;
    top: 0%;
    width: 100%;
    height: ${titleBarHeight}px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;

export const ScreenTitleBarLeft = styled.div`
    flex: 0 0 auto;
    width: 400px;
`;

export const ScreenTitleBarRight = styled.div`
    flex: 0 0 auto;
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const ScreenTitle = styled.div`
    flex: 3 3 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ScreenTitleCn = styled.div`
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 5px;
`;

export const ScreenTitleEn = styled.div`
    font-size: 18px;
    letter-spacing: 3px;
    margin-top: 5px;
`;

export const DeviceModel3DWrapper = styled.div`
    position: absolute;
    z-index: 100;
    top: calc(10% + ${titleBarHeight}px);
    left: -5%;
    width: 70%;
    height: calc(100% - ${titleBarHeight}px);
`;

export const DeviceDetailsContent = styled.div`
    z-index: 100;
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: 45%;
    min-width: 800px;
    height: calc(95% - ${titleBarHeight+20}px);
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const DetailsDetailsWrapper = styled.div`
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
`;

export const DeviceTrackWrapper = styled.div`
    z-index: 100;
    flex: 1 1 300px;
    min-height: 300px;
    padding: 10px;
    box-sizing: border-box;
`;

export const DeviceAlarmsWrapper = styled.div`
    z-index: 100;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 300px;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
`;

export const DeviceMetricsWrapper = styled.div`
    z-index: 100;
    flex: 1 1 300px;
    min-height: 300px;
    padding: 10px;
    box-sizing: border-box;
`;
