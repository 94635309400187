import { useEffect, useState } from "react";
import moment from "moment";
import { DeviceDetailsWrapper, InfoColumn, InfoItem, InfoLabel, InfoValue } from "./DeviceDetails.styled";
import DeviceStatus from "../../../../../components/ui-components/device-status/DeviceStatus";
import StringUtils from "../../../../../util/StringUtils";
import DeviceApi from "../../../../../api/DeviceApi";
import DeviceRssi from "../../../../../components/ui-components/rssi/DeviceRssi";

interface DeviceDetailsProps {
    deviceId: string
}

let DeviceDetails: React.FC<DeviceDetailsProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // state
    let [deviceView, setDeviceView] = useState(null);
    
    // functions
    let fetchDeviceViewData = async (deviceId)=> {
        let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
        setDeviceView(deviceView);
    }

    let formatDateTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        return moment(timestamp).format('YYYY/MM/DD HH:mm:ss');
    }

    // effect
    useEffect(()=> {
        fetchDeviceViewData(props.deviceId);
        let refreshTask = setInterval(async ()=> {
            await fetchDeviceViewData(props.deviceId);
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    return (
        deviceView ? <DeviceDetailsWrapper>
            <InfoColumn>
                <InfoItem>
                    <InfoLabel>设备状态</InfoLabel>
                    <InfoValue><DeviceStatus status={deviceView.node.status} /></InfoValue>
                </InfoItem>
                <InfoItem>
                    <InfoLabel>信号强度</InfoLabel>
                    <InfoValue><DeviceRssi deviceRssi={deviceView.latestHeartBeatMetric.rssi} /> </InfoValue>
                </InfoItem>
                <InfoItem>
                    <InfoLabel>定位方式</InfoLabel>
                    <InfoValue>{deviceView.latestHeartBeatMetric?.altitude == null ? '基站定位': 'GNSS'}</InfoValue>
                </InfoItem>
            </InfoColumn>

            <InfoColumn>
                <InfoItem>
                    <InfoLabel>设备名称</InfoLabel>
                    <InfoValue>{deviceView.device.name}</InfoValue>
                </InfoItem>
                <InfoItem>
                    <InfoLabel>设备编号</InfoLabel>
                    <InfoValue>{deviceView.device.deviceNo}</InfoValue>
                </InfoItem>
                <InfoItem>
                    <InfoLabel>设备模板</InfoLabel>
                    <InfoValue>{deviceView.deviceTemplate.name}</InfoValue>
                </InfoItem>
            </InfoColumn>

            <InfoColumn>
                <InfoItem>
                    <InfoLabel>产品型号</InfoLabel>
                    <InfoValue>{deviceView.node.model}</InfoValue>
                </InfoItem>
                <InfoItem>
                    <InfoLabel>备注</InfoLabel>
                    <InfoValue>{StringUtils.emptyToSlash(deviceView.device.remarks)}</InfoValue>
                </InfoItem>
                <InfoItem>
                    <InfoLabel>最后在线时间</InfoLabel>
                    <InfoValue>{formatDateTime(deviceView.latestHeartBeatMetric.timestamp)}</InfoValue>
                </InfoItem>
            </InfoColumn>
        </DeviceDetailsWrapper>
        : <DeviceDetailsWrapper></DeviceDetailsWrapper>
    )
};

export default DeviceDetails;